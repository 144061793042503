
import { Pagination, ConfigProvider } from "antd";
import { Button, Dropdown, Space } from 'antd';
import { useEffect, useState , useRef, createRef } from "react";
import { useNavigate, useLocation,  } from "react-router-dom";
import axios from "axios";
import { Bars, Puff, SpinningCircles, Audio } from 'react-loading-icons' 
import { yHost }  from "./settings";
import {  FaUserCircle } from "react-icons/fa";
import Modal from 'react-modal';
import moment from "moment";
import useitemStore from "./store"
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { IconContext } from "react-icons";
import { FaBookmark } from "react-icons/fa";
import { BiSolidLike } from "react-icons/bi";
import { FaCirclePlus } from "react-icons/fa6";
import { createHashHistory } from "history";
import { FaSearch } from "react-icons/fa";
let history = createHashHistory();





const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:'95%',
    height:"95%",
    backgroundColor:"black"
  },
};




export default function Mcqpagi() {

  const [loading, setloading ] = useState(false)
  const [btnloadingIndex, setbtnloadingIndex ] = useState()
 const [profileLoading, setprofileLoading]= useState(false)
 const [postedByProfile, setpostedByProfile] =  useState({})


  const [yObj, setyObj] = useState({})

 

  const [IsOpen, setIsOpen] = useState(false);
  //console.log(yourAns)
  const [ ansObj, setansObj] = useState({})

  const itemEls = useRef(new Array())
  const navigate = useNavigate();
  const location = useLocation();
 



  const itemsArr = useitemStore((state) => state.items)
  const setitems = useitemStore((state) => state.setitems)
  
  const mcqcount = useitemStore((state) => state.mcqcount)
  const setmcqcount = useitemStore((state) => state.setmcqcount)
  
  const yourAns = useitemStore((state) => state.yourAns)
  const setYourAns = useitemStore((state) => state.setYourAns)
  
  const keyVal = useitemStore((state) => state.keyVal)
  const setkeyVal = useitemStore((state) => state.setkeyVal)
  
  const myBookMarks = useitemStore((state) => state.myBookMarks)
  const setmyBookMarks = useitemStore((state) => state.setmyBookMarks)
  
  const myLikes = useitemStore((state) => state.myLikes)
  const setmyLikes = useitemStore((state) => state.setmyLikes)

  const expSubmitted = useitemStore((state) => state.expSubmitted)
const setexpSubmitted = useitemStore((state) => state.setexpSubmitted)

const myMcqsAnsArr = useitemStore((state) => state.myMcqsAnsArr)
const setmyMcqsAnsArr = useitemStore((state) => state.setmyMcqsAnsArr)

  const score = useitemStore((state) => state.score)
  const setScore = useitemStore((state) => state.setScore)

  const fname = useitemStore((state) => state.fname)
  const setfname = useitemStore((state) => state.setfname)

  const loggedInUserEmail = useitemStore((state) => state.loggedInUserEmail)
  const setloggedInUserEmail = useitemStore((state) => state.setloggedInUserEmail)


  useEffect(() => {

     console.log(location.state)
    // if(history.location.key=="default"){ fetchMcqs(keyVal);}

    if(!itemsArr.length){ console.log("useEffect1", itemsArr.length);  fetchMcqs(localStorage.getItem('keyVal'))  }
    // console.log("useEffect", itemsArr.length)

      }, []);


      const logOut=async()=>{


        axios({
            method: 'post',
            url: `${yHost}/users/logout`,
            withCredentials:true
            
          })
        .then((response) => {
        
          const data = response.data;
          console.log(data)
         
          if(data.msg=="You are logged out"){   
      setfname("")
      setloggedInUserEmail("")
      alert("You are logged out") 
      navigate("/")
        }
          else{alert(data.msg)}
        })
        .catch((error) => console.log(error));
    
    }


      const fetchMcqs = async (val) => {
        console.log("val keyval ", val, keyVal)

         setloading(true) 
         var iObj =  {onlyExplanationMcqs: location.state ? location.state.onlyExplanationMcqs: false, onlyImageMcqs: location.state ? location.state.onlyImageMcqs:false , selectedSubj:location.state ? location.state.selectedSubj:"", selectedExam:location.state ? location.state.selectedExam:"" , keyword:location.state?location.state.keyword:"", keyVal:val-1  }
         
         axios({
             method: 'post',
             url: `${yHost}/fetchMcqs`,
             data:iObj,
             withCredentials: true //ye cookies carry karega
           })
         .then((response) => {
             setloading(false) 
             const data = response.data;
             console.log("fetchmcq reply 2", data)
             if(data.msg == "please log in"){alert("please log in"); navigate("/")  }
          
           if (!data.mcqArr.length) {
             alert('no records') 
             setloggedInUserEmail(data.email)
             setfname(data.fname)
            }
           else {    
              console.log(data.mcqArr[0])
              setitems(data.mcqArr)
              setmcqcount(data.mcqcount)
              setYourAns(data.corans)
              setmyBookMarks(data.myBookMarks)
              setmyLikes(data.mylikesArr)
              setkeyVal(val)
              setScore(data.score)
              
              localStorage.setItem('keyVal', val);
              setloggedInUserEmail(data.email)
              setfname(data.fname)
              setexpSubmitted(data.expSubmitted)
              setmyMcqsAnsArr(data.myMcqsAnsArr)
              console.log("myMcqsAnsArr mcqpagi",data.myMcqsAnsArr)
              
         }
         })
         .catch((error) => console.log(error));
     }

       
const submitAns = (mcqnum,index) => {

  if(!yourAns.filter((i)=>{ if( i.mcqnum == mcqnum){return i}  }).length){
    alert("please select any option first")
    return
  }
//console.log(index)
//console.log(itemEls.current.length)
//itemEls.current.forEach((i)=>{i.style.paddingRight = "100px"})
//itemEls.current[index].style.paddingRight = "100px"
//itemEls.current.filter((i)=>{  if(index==i.index){ return i }  })[0].element.style.display="none" //working

setbtnloadingIndex(index)

  axios({
    method: 'post',
    url: `${yHost}/submitAns`,
    data: yourAns.filter((i)=>{ if( i.mcqnum == mcqnum){return i}  })[0],
    withCredentials:true

  })
    .then((response) => {
      const data = response.data; 

      var arr1 = yourAns.filter((i)=>{ if(i.mcqnum !=mcqnum ){ return i}})
      var addObj = {mcqnum:mcqnum, ans:yourAns.filter((i)=>{ if( i.mcqnum == mcqnum){return i}  })[0].ans , rightAns:data.mcqobj.ans }
      setYourAns([...arr1,addObj])
      setansObj(data.mcqobj)
      setbtnloadingIndex(null)
      setScore(data.score)
     
    
    })
    .catch((error) => console.log(error));
}


const submitAnsOwnMcq = (mcqnum) =>{
  if(!yourAns.filter((i)=>{ if( i.mcqnum == mcqnum){return i}  }).length){
    alert("please select any option first")
    return
  }
  console.log(yourAns.filter((i)=>{ if( i.mcqnum == mcqnum){return i}  })[0])
  axios({
    method: 'post',
    url: `${yHost}/submitAnsOwnMcq`,
    data: yourAns.filter((i)=>{ if( i.mcqnum == mcqnum){return i}  })[0],
    withCredentials:true

  })
    .then((response) => {
      const data = response.data; 
     if(data.msg=="success"){
      alert("submitted successfully"); 
     var newArr = [...myMcqsAnsArr,yourAns.filter((i)=>{ if( i.mcqnum == mcqnum){return i}  })[0]]
    setmyMcqsAnsArr(newArr)
    }
     
     
     
    
   })
    .catch((error) => console.log(error));

}

function afterOpenModal() {
}


const fetchUserProfile = (email)=>{
  console.log("email", email)
  axios({
    method: "post",
    url: `${yHost}/users/fetchUserProfile`,
    data:{email:email},
    withCredentials: true,
  })
    .then((response) => {
      const data = response.data;
      //console.log(data);
      if(data.msg=="please login") {alert("please login")}
      else{
        data.userObj.postedmcqsCount = data.postedmcqsCount
        setpostedByProfile(data.userObj);
      }
      setprofileLoading(false);
    })
    .catch((error) => console.log(error));
}



const addLike = (mcqnum)=>{
  var arr =[...myLikes,mcqnum]
  
    axios({
      method: 'post',
      url: `${yHost}/users/addLike`,
      data: {myLikes:arr, presentLike:mcqnum},
      withCredentials:true
  
    })
      .then((response) => {
        const data = response.data; 
        if(data.msg=="success"){
          setmyLikes(arr)
          
      var tArr = itemsArr.map((i)=>{  if(i.mcqnum==mcqnum){  i.totalLikes =  i.totalLikes +1    }  ; return i  })

          setitems(tArr)

        }else if(data.msg=="please login"){
         alert("please login")
        }
        
      })
      .catch((error) => console.log(error));
}

const removeLike = (mcqnum)=>{
  console.log("myLikes",myLikes)
  var arr = myLikes.filter((i)=>{ if(i != mcqnum){return i}  })
  
  axios({
    method: 'post',
    url: `${yHost}/users/removeLike`,
    data: {myLikes:arr,presentLike:mcqnum},
    withCredentials:true

  })
    .then((response) => {
      const data = response.data; 
      if(data.msg=="success"){
        setmyLikes(arr)
        var tArr = itemsArr.map((i)=>{  if(i.mcqnum==mcqnum){  i.totalLikes =  i.totalLikes - 1    }  ; return i  })

          setitems(tArr)


      }else if(data.msg=="please login"){
       alert("please login")
      }
      
    })
    .catch((error) => console.log(error));


}


const addBookmark = (mcqnum)=>{
  var arr =[...myBookMarks,mcqnum]
  
    axios({
      method: 'post',
      url: `${yHost}/users/addBookmark`,
      data: {myBookMarks:arr},
      withCredentials:true
  
    })
      .then((response) => {
        const data = response.data; 
        if(data.msg=="success"){
          setmyBookMarks(arr)
        }else if(data.msg=="please login"){
         alert("please login")
        }
        
      })
      .catch((error) => console.log(error));
}


const removeBookmark = (mcqnum)=>{
  var arr = myBookMarks.filter((i)=>{ if(i != mcqnum){return i}  })
  
  axios({
    method: 'post',
    url: `${yHost}/users/removeBookmark`,
    data: {myBookMarks:arr},
    withCredentials:true

  })
    .then((response) => {
      const data = response.data; 
      if(data.msg=="success"){
        setmyBookMarks(arr)
      }else if(data.msg=="please login"){
       alert("please login")
      }
      
    })
    .catch((error) => console.log(error));


}

const seeContriAns = (mcqnum)=>{


    if(ansObj.mcqnum==mcqnum){ navigate("/contrians" , { state:{ mcqnum: mcqnum , ansObj: ansObj}})}
    else{
      axios({
        method: 'post',
        url: `${yHost}/seeContriAns/`+mcqnum,
        withCredentials:true
      })
        .then((response) => {
          const data = response.data; 
          console.log("ye", data.ansObj )
          if(data.msg=="success"){  
            navigate("/contrians" , { state:{ mcqnum: mcqnum , ansObj: data.ansObj}})
          }else if(data.msg=="please login"){
           alert("please login")
          }
          
        })
        .catch((error) => console.log(error));
    }
   
}


const seeAdminAns = (mcqnum)=>{
    if(ansObj.mcqnum==mcqnum){ navigate("/adminans" , { state:{ mcqnum: mcqnum , ansObj: ansObj}})}
    else{ 
      axios({
        method: 'post',
        url: `${yHost}/seeAdminAns/`+mcqnum,
        withCredentials:true
      })
        .then((response) => {
          const data = response.data; 
          if(data.msg=="success"){
           
            navigate("/adminans" , { state:{ mcqnum: mcqnum , ansObj: data.ansObj}})
          }else if(data.msg=="please login"){
           alert("please login")
          }
          
        })
        .catch((error) => console.log(error));
    }
}


const seeAllUsersAns = (mcqnum)=>{

       if(ansObj.mcqnum==mcqnum){ navigate("/alluserans" , { state:{ mcqnum: mcqnum , ansObj: ansObj}})}
    else{
      axios({
        method: 'post',
        url: `${yHost}/seeAllUsersAns/`+mcqnum,
        withCredentials:true
      })
        .then((response) => {
          const data = response.data; 
          if(data.msg=="success"){
           
            navigate("/alluserans" , { state:{ mcqnum: mcqnum , ansObj: data.ansObj}})
          }else if(data.msg=="please login"){
           alert("please login")
          }
          
        })
        .catch((error) => console.log(error));
    } 

}


const items = [
  {
    key: '1',
    style: {backgroundColor:"grey"},
    onClick:()=>{setIsOpen(true) ; setprofileLoading(true) ; fetchUserProfile(loggedInUserEmail) },
    label: (
      <span>{fname}</span>
    ),
  },
  {
    key: '3',
    style: {backgroundColor:"orange",marginBottom:"3px"},
    onClick: () => navigate(`/profile`),
    label: (
        <span>Profile</span>
    ),
   
  },
  {
    key: '4',
    style: {backgroundColor:"grey" ,marginBottom:"3px"},
    onClick:(e)=>logOut(),
    label: 'log out',
  },
 
]; 


  return (
    <div style={{backgroundColor:"black"}}>

      {!loading?
      <div
        style={{
          zIndex: "1000",
          position: "fixed",
          top: "27px",
          right: "170px",
          padding: "10px",
        }}
        onClick={() => {
          console.log("go to route");
        }}
      >
        <Dropdown
          menu={{
            items,
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <IconContext.Provider
                value={{
                  size: "30px",
                  color: "orange",
                  className: "global-class-name",
                }}
              >
                <div>
                  <FaUserCircle className="hoverSearch arrow" />
                </div>
              </IconContext.Provider>
            </Space>
          </a>
        </Dropdown>
      </div>
      :null}    


      <Modal
        isOpen={IsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={()=>setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        
      >
        <Button onClick={()=>{ setIsOpen(false)}} type="primary">X</Button>
        {profileLoading ?
        <div style={{width:"100%", height:"100%", position:'absolute'}}>
          <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
          </div>:
          <div>
          <h1 style={{textAlign:"center"}}>{postedByProfile.fname}</h1>
          <p style={{color:"white"}}>{postedByProfile.fname} {postedByProfile.surname}</p>  
          <img src={`${yHost}/userPics/` + postedByProfile.profilePic } style={{
            width: "100%",
            maxWidth: "200px",
            height: "auto",
           
            marginTop: "40px",
          }}
          alt="Picture"
        />
         <p style={{color:"white"}}>MCQs Posted: {postedByProfile.postedmcqsCount}</p>
         <p style={{color:"white"}}>Score: {postedByProfile.totalscore}</p>
         <p style={{color:"white"}}>MCQs Answered: {postedByProfile.corans ? postedByProfile.corans.length : ""}</p>
         <p style={{color:"white"}}>MCQs Answered Correct : {postedByProfile.corans ? postedByProfile.corans.length-postedByProfile.yourRightAnswers : null}</p>
         <p style={{color:"white"}}>MCQs Answered Wrong : {postedByProfile.yourRightAnswers}</p>
         <p style={{color:"white"}}>MCQs Answered with Explanation : {postedByProfile.expSubmitted ? postedByProfile.expSubmitted.length : ""}</p>
      
         <p style={{color:"white"}}>Country: {postedByProfile.country}</p>
         
         <p style={{color:"white"}}>UG college: {postedByProfile.ugcollege}</p>

          </div>
        }
      </Modal>
   


       {loading?
          <div style={{width:"100%", height:"3000px", backgroundColor:"brown", position:'absolute'}}>
          <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
          </div>
                 :
   <div>

<div style={{backgroundColor:"grey"}}>
  <ConfigProvider
    theme={{
      token: {
        itemActiveBg:"#00ffff",
        itemBg:"#0e9b9b",
        itemInputBg:"#0e9b9b"
      },
    }}
  >
    <Pagination
    defaultCurrent={keyVal}
    className="pagi"
    total={mcqcount}
    defaultPageSize={100}
    pageSizeOptions={[100]}
    showSizeChanger={false}
    showQuickJumper
    showTotal={(total) => `${total} mcqs`}
    align="center"
    onChange={(openThisPage, perPageCount)=>{ console.log("open",openThisPage) ; fetchMcqs(openThisPage)   }}
    style={{zIndex:99999999}}
   />
  </ConfigProvider>
</div>
  




<div style={{marginTop:"70px" }}>
 <p style={{color:"white", borderWidth:"1px", borderColor:"aqua", borderRadius:"5px",borderStyle:"solid", position:"fixed", top:"20px", right:"10px", padding:"3px"}}>Score: {score}</p>
 <FaCirclePlus onClick={()=>{navigate("/postamcq")}} style={{color:"orange", position:"fixed", top:"36px", right:"85px", width:"30px",  height:"30px", cursor:"pointer"}} />
 <FaSearch onClick={()=>{navigate("/")}} style={{color:"orange", position:"fixed", top:"37px", right:"130px", width:"30px",  height:"30px", cursor:"pointer"}} />
 

{itemsArr.map((i,index)=>{ return (
                <div style={loggedInUserEmail!=i.postedByEmail?{width:"100%",maxWidth:"600px", borderColor: "aqua", borderWidth:"1px", borderStyle:"solid", borderRadius:"5px", marginRight:"auto", marginLeft:"auto", display:"block",paddingLeft:"20px",paddingRight:"10px",marginTop:"10px" }:{backgroundColor:"#282829", width:"100%",maxWidth:"600px", borderColor: "aqua", borderWidth:"1px", borderStyle:"solid", borderRadius:"5px", marginRight:"auto", marginLeft:"auto", display:"block",paddingLeft:"20px",paddingRight:"10px",marginTop:"10px" }  } key={index} >
                
                 { (i.question.includes("<p") || i.question.includes("<li") || i.question.includes("<span"))  && i.question.includes("</") ? 
                 <div style={{backgroundColor:"white", padding:"7px", marginBottom:"7px", marginTop:"5px"}}>{ReactHtmlParser(i.question)}</div>
                 :
                 <p><span style={{color:"grey"}}>{i.mcqnum}. </span> <span style={{color:"orange", }}>{i.question}</span></p>
                  }
                
              
                 
                 {i.contriQuespic ?
                 <img src={yHost+"/uploads/" + i.contriQuespic       } alt="pic"    style={{width:"100%", maxWidth:"400px",height:"auto", marginBottom:"8px"}}/>
                 :
                 null } 
                 

                 <div style={{display:"flex", flexDirection:"row"}}> 
                 
                 <img onClick={()=>{setIsOpen(true) ; setprofileLoading(true) ; fetchUserProfile(i.postedByEmail) }} src={yHost+"/userPics/" +   i.postedByProfilePic    } alt="pic" style={{width:"50px", height:"50px", verticalAlign:"middle",borderRadius:"50%", marginRight:"10px", cursor:"pointer"}}/>
                 <p style={{color:"white", fontSize:"10px", marginRight:"3px"}}>posted by</p>
                 <p style={{color:"orange", fontSize:"12px", marginBottom:"10px"}}>{i.postedBy} at {moment(i.createdOn).fromNow()}</p> 
                 </div> 


                 <p style={{color:"white", fontSize:"12px", marginBottom:"10px"}}>from subject: {i.subject}</p> 

                {i.ExamArr.length ?
                               <p style={{color:"white", fontSize:"12px", marginBottom:"10px"}}>from Exams: {i.ExamArr.map((it,itx)=>{  return (
                                <span key={"Exam"+itx}>
                                <span>{it}</span><br/>
                                </span>
                               )  })    }</p>
                               :null
                }
   


                 <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                 <input type="checkbox"   
                  className="optionCheckbox"
                 value= { "A" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns) }
               
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="A") ? true :   ! yourAns.length ? false :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="A") ? true : false   }
                onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })

                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }


                        
                      }}  
                />
               { i.option1.includes("<")  && i.option1.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>A: </span>{ReactHtmlParser(i.option1)}</div>
                 :
                 <p><span style={{color:"orange"}}>A: </span><span style={{color:"white"}}>{i.option1}</span></p>
               }
                 
                 </div>



                 <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                 <input type="checkbox"   
                 className="optionCheckbox"
                 value= { "B" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
               
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="B") ? true :   ! yourAns.length ? false :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="B") ? true : false   }
                onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option2.includes("<")  && i.option2.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>B: </span>{ReactHtmlParser(i.option2)}</div>
                 :
                 <p><span style={{color:"orange"}}>B: </span><span style={{color:"white"}}>{i.option2}</span></p>
               }
                 </div>



                 <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                 <input type="checkbox"   
                  className="optionCheckbox"
                 value= { "C" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
              
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="C") ? true :   ! yourAns.length ? false :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="C") ? true : false   }
                onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option3.includes("<")  && i.option3.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>C: </span>{ReactHtmlParser(i.option3)}</div>
                 :
                 <p><span style={{color:"orange"}}>C: </span><span style={{color:"white"}}>{i.option3}</span></p>
               }
                 </div>



               <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                 <input type="checkbox"   
                 className="optionCheckbox"
                 value= { "D" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="D") ? true :   ! yourAns.length ? false :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="D") ? true : false   }
                onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option4.includes("<")  && i.option4.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>D: </span>{ReactHtmlParser(i.option4)}</div>
                 :
                 <p><span style={{color:"orange"}}>D: </span><span style={{color:"white"}}>{i.option4}</span></p>
               }
                 </div>
                

                
                {i.option5 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "E" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="E") ? true :   ! yourAns.length ? false :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="E") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option5.includes("<")  && i.option5.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>E: </span>{ReactHtmlParser(i.option5)}</div>
                 :
                 <p><span style={{color:"orange"}}>E: </span><span style={{color:"white"}}>{i.option5}</span></p>
               }
                 </div>:null}


                 {i.option6 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "F" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="F") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="F") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option6.includes("<")  && i.option6.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>F: </span>{ReactHtmlParser(i.option6)}</div>
                 :
                 <p><span style={{color:"orange"}}>F: </span><span style={{color:"white"}}>{i.option6}</span></p>
               }
                 </div>:null}


                 {i.option7 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "G" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="G") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="G") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option7.includes("<")  && i.option7.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>G: </span>{ReactHtmlParser(i.option7)}</div>
                 :
                 <p><span style={{color:"orange"}}>G: </span><span style={{color:"white"}}>{i.option7}</span></p>
               }
                 </div>:null}


                 {i.option8 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "H" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="H") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="H") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option8.includes("<")  && i.option8.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>H: </span>{ReactHtmlParser(i.option8)}</div>
                 :
                 <p><span style={{color:"orange"}}>H: </span><span style={{color:"white"}}>{i.option8}</span></p>
               }
                 </div>:null}

                 {i.option9 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "I" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="I") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="I") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option9.includes("<")  && i.option9.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>I: </span>{ReactHtmlParser(i.option9)}</div>
                 :
                 <p><span style={{color:"orange"}}>I: </span><span style={{color:"white"}}>{i.option9}</span></p>
               }
                 </div>:null}


                 {i.option10 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "J" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="J") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="J") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option10.includes("<")  && i.option10.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>J: </span>{ReactHtmlParser(i.option10)}</div>
                 :
                 <p><span style={{color:"orange"}}>J: </span><span style={{color:"white"}}>{i.option10}</span></p>
               }
                 </div>:null}


                 {i.option11 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "K" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="K") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="K") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option11.includes("<")  && i.option11.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>K: </span>{ReactHtmlParser(i.option11)}</div>
                 :
                 <p><span style={{color:"orange"}}>K: </span><span style={{color:"white"}}>{i.option11}</span></p>
               }
                 </div>:null}


                 {i.option12 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "F" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="F") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="F") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option12.includes("<")  && i.option12.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>L: </span>{ReactHtmlParser(i.option12)}</div>
                 :
                 <p><span style={{color:"orange"}}>L: </span><span style={{color:"white"}}>{i.option12}</span></p>
               }
                 </div>:null}


                 {i.option13 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "M" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="M") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="M") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option13.includes("<")  && i.option13.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>M: </span>{ReactHtmlParser(i.option13)}</div>
                 :
                 <p><span style={{color:"orange"}}>M: </span><span style={{color:"white"}}>{i.option13}</span></p>
               }
                 </div>:null}

                 {i.option14 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "N" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="N") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="N") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option14.includes("<")  && i.option14.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>N: </span>{ReactHtmlParser(i.option14)}</div>
                 :
                 <p><span style={{color:"orange"}}>N: </span><span style={{color:"white"}}>{i.option14}</span></p>
               }
                 </div>:null}


                 {i.option15 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "O" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="O") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="O") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option15.includes("<")  && i.option15.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>O: </span>{ReactHtmlParser(i.option15)}</div>
                 :
                 <p><span style={{color:"orange"}}>O: </span><span style={{color:"white"}}>{i.option15}</span></p>
               }
                 </div>:null}


                 {i.option16 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "P" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="P") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="P") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option16.includes("<")  && i.option16.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>P: </span>{ReactHtmlParser(i.option16)}</div>
                 :
                 <p><span style={{color:"orange"}}>P: </span><span style={{color:"white"}}>{i.option16}</span></p>
               }
                 </div>:null}


                 {i.option17 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "Q" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="Q") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="Q") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option17.includes("<")  && i.option17.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>Q: </span>{ReactHtmlParser(i.option17)}</div>
                 :
                 <p><span style={{color:"orange"}}>Q: </span><span style={{color:"white"}}>{i.option17}</span></p>
               }
                 </div>:null}


                 {i.option18 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "R" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="R") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="R") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option18.includes("<")  && i.option18.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>R: </span>{ReactHtmlParser(i.option18)}</div>
                 :
                 <p><span style={{color:"orange"}}>R: </span><span style={{color:"white"}}>{i.option18}</span></p>
               }
                 </div>:null}


                 {i.option19 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "S" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="S") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="S") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option19.includes("<")  && i.option19.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>S: </span>{ReactHtmlParser(i.option19)}</div>
                 :
                 <p><span style={{color:"orange"}}>S: </span><span style={{color:"white"}}>{i.option19}</span></p>
               }
                 </div>:null}


                 {i.option20 ?
                   <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}> 
                   <input type="checkbox"   
                   className="optionCheckbox"
                 value= { "T" }
                 disabled={myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length || (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length && yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].rightAns )}
                 checked={( myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length && myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} })[0].ans =="T") ? true :   ! yourAns.length ? false 
                 :    (yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length &&  yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="T") ? true : false   }
                 onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                  { i.option20.includes("<")  && i.option20.includes("</") ? 
                  <div style={{color:"white"}}><span style={{color:"orange"}}>T: </span>{ReactHtmlParser(i.option20)}</div>
                 :
                 <p><span style={{color:"orange"}}>T: </span><span style={{color:"white"}}>{i.option20}</span></p>
               }
                 </div>:null}

                




                {loggedInUserEmail==i.postedByEmail ?  
                <div>
                <p style={{color:"orange"}}>This mcq is posted by you</p> 
                {!myMcqsAnsArr.filter((It)=>{  if(i.mcqnum==It.mcqnum){return It} }).length ? 
                <>
                <p style={{color:"orange"}}>you didn't given answer yet</p> 
                <Button id={index} type="primary" onClick={(e) => { submitAnsOwnMcq(i.mcqnum) }} ref={(element) => itemEls.current.push({ element: element, index: index })}>
              Submit
            </Button> 
                </>
                :
                <>
                 <br />
              <Button style={{}} type="primary" onClick={() => seeContriAns(i.mcqnum)}>
                See your Answer {i.contriPostedExpl ? "and Explanation" : null}
              </Button>
                </>
                }
             
             
            
               {i.contriPostedExpl ?
               <div>
                <p style={{color:"orange"}}>You have posted explanation with this answer</p> 
               
                <Button style={{}} type="primary" onClick={() => {navigate('/updateexpl', {state:{mcqnum: i.mcqnum, ownmcq:true}} )}}>
                update Explanation
                </Button>
               </div>:
               <>
               <br/> 
               <br/> 
              <Button style={{}} type="primary" onClick={() => {navigate('/submitexpl', {state:{...i, ownmcq:true }})}}>
               Post Explanation
              </Button>
              <br />
              </>
               }
                
                </div>
                :
                  yourAns.filter((itt)=>{  if(itt.mcqnum == i.mcqnum ){return itt}   }).length && yourAns.filter((itt)=>{  if(itt.mcqnum == i.mcqnum ){return itt}   })[0].rightAns ? null:
                <>{btnloadingIndex? null:
                  <>
                <br/>
                 <p style={{color:"white"}}>To see right answer, first Submit your answer.</p></>
                }</>
                
              }
                




    <div style={{ color: "white" }}>
     {loggedInUserEmail != i.postedByEmail ?
      yourAns.filter((itt) => { if (itt.mcqnum == i.mcqnum) { return itt } }).length && yourAns.filter((itt) => { if (itt.mcqnum == i.mcqnum) { return itt } })[0].rightAns ?

        yourAns.filter((itt) => { if (itt.mcqnum == i.mcqnum) { return itt } })[0].ans == yourAns.filter((itt) => { if (itt.mcqnum == i.mcqnum) { return itt } })[0].rightAns ?
          <div>
            <p>Right</p>
           
            {!expSubmitted.includes(i.mcqnum) ?
            <Button type="primary" onClick={() => navigate("/submitexpl", { state: { ...i,ownmcq:false, ans: yourAns.filter((itt) => { if (itt.mcqnum == i.mcqnum) { return itt } })[0].ans } })}>
              Please Submit your explanation / textbook references
            </Button>
            :
            <Button type="primary" onClick={() => navigate("/updateexpl", { state: {mcqnum:i.mcqnum, ownmcq:false} })}>
            update your explanation / textbook references
           </Button>}
          </div>
          :
          yourAns.filter((itt) => { if (itt.mcqnum == i.mcqnum) { return itt } })[0].rightAns == "NC" ?
            <p>{i.postedBy} has not provided right answer</p>
            :
            <div>
              <p>Wrong</p>

            </div>
        :
        <div>
          {index != btnloadingIndex ?
            <Button id={index} type="primary" onClick={(e) => { submitAns(i.mcqnum, index, e) }} ref={(element) => itemEls.current.push({ element: element, index: index })}>
              Submit
            </Button> :
            <div style={{ marginRight: "auto", marginRight: "auto", display: "block", width: "50%", maxWidth: "80px" }}><Bars /></div>
          }
        </div>
      
      :null} 

      {i.adminAnsYesNo && loggedInUserEmail == i.postedByEmail ?
        <div>
          <br />
          <br />
          <Button style={{}} type="primary" onClick={() => seeAdminAns(i.mcqnum)}>
            See admin's Answer {i.adminPostedExpl ? "and Explanation" : null}
          </Button>
        </div>
        : null
      }

      { yourAns.filter((iit) => { if (i.mcqnum == iit.mcqnum) { return iit } }).length && yourAns.filter((iit) => { if (i.mcqnum == iit.mcqnum) { return iit } })[0].rightAns  ?
        <div>

          {i.adminAnsYesNo ?
            <>
              <br />
              <br />
              <Button style={{}} type="primary" onClick={() => seeAdminAns(i.mcqnum)}>
                See admin's Answer {i.adminPostedExpl ? "and Explanation" : null}
              </Button>
            </> : null}


          {yourAns.filter((itt) => { if (itt.mcqnum == i.mcqnum) { return itt } })[0].rightAns != "NC" ?
            <div>
              <br />
              <br />
              <Button style={{}} type="primary" onClick={() => seeContriAns(i.mcqnum)}>
                See {i.postedBy}'s Answer {i.contriPostedExpl ? "and Explanation" : null}
              </Button>
            </div> : null}

        </div>
        : null
      }











      {i.allanswerCount ?
        <>
          <br />
          <br />
          <Button style={{}} type="primary" onClick={() => seeAllUsersAns(i.mcqnum)}>
            {i.allanswerCount} users answered this question, see all
          </Button></> : null
      }
      <br />

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        {myBookMarks.includes(i.mcqnum) ?
          <div onClick={() => removeBookmark(i.mcqnum)} style={{ display: "flex", cursor: "pointer", marginTop: "20px" }}>
            <FaBookmark style={{ color: "orange", width: "20px", height: "40px" }} />
            <p style={{ fontSize: "11px", marginLeft: "10px" }}>Remove BookMark</p>
          </div>
          :
          <div onClick={() => addBookmark(i.mcqnum)} style={{ display: "flex", cursor: "pointer", marginTop: "20px" }}>
            <FaBookmark style={{ color: "grey", width: "20px", height: "40px" }} />
            <p style={{ fontSize: "11px", marginLeft: "10px" }}>Add BookMark</p>
          </div>
        }



        {myLikes.includes(i.mcqnum) ?
          <div onClick={() => removeLike(i.mcqnum)} style={{ display: "flex", cursor: "pointer", marginTop: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <BiSolidLike style={{ color: "orange", width: "34px", height: "34px" }} />
              <span style={{ color: "white", fontSize: "12px", marginRight: "5px" }}>{i.totalLikes} Likes</span>
            </div>
            <p style={{ fontSize: "11px", marginLeft: "10px" }}>Remove Like</p>
          </div>
          :
          <div onClick={() => addLike(i.mcqnum)} style={{ display: "flex", cursor: "pointer", marginTop: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <BiSolidLike style={{ color: "grey", width: "34px", height: "34px" }} />
              <span style={{ color: "white", fontSize: "12px", marginRight: "5px" }}>{i.totalLikes} Likes</span>
            </div>
            <p style={{ fontSize: "11px", marginLeft: "10px" }}>Like this Mcq</p>
          </div>
        }

      </div>
    </div>
                   
                </div> 
                
            )})}
</div>


  </div>
  }





  </div>
  );
}

