import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from "./error-page";
import Mcqpagi from "./mcqPagi";
import Postamcq from "./postamcq";
import Teachers from './teachers';
import Contrians from './contriAns'
import AdminAns from './adminAns'
import AllUserAns from './allUserAns'
import Profile from './profile'
import SubmitExpl from './submitExpl'
import Gpage from './gPage'
import UpdateExpl from './updateExpl'

import Success from './successMakeAccount'


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Mcqs from './mcqs';

const router = createBrowserRouter([
 
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/index.html",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/mcqs",
    element: <Mcqs />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/mcqPagi",
    element: <Mcqpagi />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/postamcq",
    element: <Postamcq />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/teachers",
    element: <Teachers />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contrians",
    element: <Contrians />, 
    errorElement: <ErrorPage />,
  },
  {
    path: "/adminans",
    element: <AdminAns />, 
    errorElement: <ErrorPage />,
  },
  {
    path: "/alluserans",
    element: <AllUserAns />, 
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile",
    element: <Profile />, 
    errorElement: <ErrorPage />,
  },
  {
    path: "/submitexpl",
    element: <SubmitExpl />, 
    errorElement: <ErrorPage />,
  },
  {
    path: "/gpage/:mcqnum",
    element: <Gpage />, 
    errorElement: <ErrorPage />,
  },
  
  {
    path: "/updateexpl",
    element: <UpdateExpl />, 
    errorElement: <ErrorPage />,
  },
  {
    path: "/success",
    element: <Success />, 
    errorElement: <ErrorPage />,
  },
 
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <RouterProvider router={router} />
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
