
import {Helmet} from "react-helmet";

export default function Footer() {


  return (
    <footer>
   
    <div id="footer" className="footer-widgets">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-xl-3">
                    <div className="single-widget contact-widget" data-aos="fade-up" data-aos-delay="0">
                        <h6 className="widget-tiltle">Office</h6>
                      
                        <div className="media">
                            <i className="fa fa-map-marker"></i>
                            <div className="media-body ml-3">
                                <h6>Address</h6>
                                Kharghar<br />
                                Navi Mumbai
                            </div>
                        </div>
                        <div className="media">
                            <i className="fa fa-envelope-o"></i>
                            <div className="media-body ml-3">
                                <h6>Have any questions?</h6>
                                <a href="mailto:mcqbuster@gmail.com">mcqbuster@gmail.com</a>
                            </div>
                        </div>
                        <div className="media">
                            <i className="fa fa-phone"></i>
                            <div className="media-body ml-3">
                                <h6>WhatsApp us</h6>
                                <a href="tel:+610791803458"> +91 8591268994</a>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="single-widget twitter-widget" data-aos="fade-up" data-aos-delay="200">
                        <h6 className="widget-tiltle">Fresh comments / feedbacks</h6>
                        <div className="media">

                            <div className="media-body ml-3">
                                <h6><a href="#">@Mayur,</a> Great, V good App</h6>
                                <span>20th Oct</span>
                            </div>
                        </div>
                        <div className="media">

                            <div className="media-body ml-3">
                                <h6><a href="#">@Raghav,</a> V good collection of MCQs</h6>
                                <span>25th Oct</span>
                            </div>
                        </div>
                        <div className="media">

                            <div className="media-body ml-3">
                                <h6><a href="#">@Akash,</a>I recommend it</h6>
                                <span>30th Oct</span>
                            </div>
                        </div>
                        <div className="media">

                            <div className="media-body ml-3">
                                <h6><a href="#">@Krishna,</a> Please upload 2021 NEETPG MCQS</h6>
                                <span>15 Mins Ago</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="single-widget recent-post-widget" data-aos="fade-up" data-aos-delay="400">
                        <h6 className="widget-tiltle">Latest Updates</h6>
                        <div className="media">
                            <a className="rcnt-img" href="#"><img src="assets/images/rcnt-post1.png"
                                alt="Recent Post" /></a>
                            <div className="media-body ml-3">
                                <h6><a href="#">"Doctors provide healing touch to ailing humanity": President Murmu at 10th conv.</a></h6>
                                <p><i className="fa fa-user"></i>Mano <i className="fa fa-eye"></i> 202 Views</p>
                            </div>
                        </div>
                        <div className="media">
                            <a className="rcnt-img" href="#">
                                <img src="assets/images/rcnt-post2.png"
                                    alt="Recent Post" /></a>
                            <div className="media-body ml-3">
                                <h6><a href="#">Cardiac centre will be set up under PPP model: Medical education minister Sharanprakash Patil</a></h6>
                                <p><i className="fa fa-user"></i>Rosias <i className="fa fa-eye"></i> 20 Views</p>
                            </div>
                        </div>
                        <div className="media">
                            <a className="rcnt-img" href="#">
                                <img src="assets/images/rcnt-post3.png"
                                    alt="Recent Post" /></a>
                            <div className="media-body ml-3">
                                <h6><a href="#">Power outage for over 3 hours at SAT Hospital leads to panicg</a></h6>
                                <p><i className="fa fa-user"></i>Kien <i className="fa fa-eye"></i> 74 Views</p>
                            </div>
                        </div>
                        <div className="media">
                            <a className="rcnt-img" href="#">
                                <img src="assets/images/rcnt-post4.png"
                                    alt="Recent Post" /></a>
                            <div className="media-body ml-3">
                                <h6><a href="#">An engaging embedded the video posts</a></h6>
                                <p><i className="fa fa-user"></i>Robert <i className="fa fa-eye"></i> 48 Views</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="single-widget tags-widget" data-aos="fade-up" data-aos-delay="800">
                        <h6 className="widget-tiltle">Popular Tags</h6>
                        <a href="#">NEETPG</a>
                        <a href="#">AIIMS</a>
                        <a href="#">PGI</a>
                        <a href="#">JIPMER</a>
                        <a href="#">USMLE</a>
                        <a href="#">PLAB</a>
                    </div>
                    <div className="single-widget subscribe-widget" data-aos="fade-up" data-aos-delay="800">
                        <h6 className="widget-tiltle">Subscribe us</h6>
                        <p>Sign up for our mailing list to get latest updates and offers</p>
                        <form className="" method="get">
                            <div className="input-group">
                                <input className="field form-control" name="subscribe" type="email"
                                    placeholder="Email Address" />
                                <span className="input-group-btn">
                                    <button type="submit" name="submit-mail"><i className="fa fa-check"></i></button>
                                </span>
                            </div>
                        </form>
                        <p>We respect your privacy</p>
                        <ul className="nav social-nav">
                            <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                            <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                            <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="foot-note">
        <div className="container">
            <div
                className="footer-content text-center text-lg-left d-lg-flex justify-content-between align-items-center">
                <p className="mb-0" data-aos="fade-right" data-aos-offset="0">&copy; 2022 All Rights Reserved. Design by <a href="#" target="_blank" className="fh5-link">gagan</a>.</p>
                <p className="mb-0" data-aos="fade-left" data-aos-offset="0"><a href="#">Terms Of Use</a><a
                    href="#">Privacy & Security
                    Statement</a></p>
            </div>
        </div>
    </div>

</footer>

  );
}

