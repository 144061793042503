

import {useParams} from "react-router-dom";
import { useEffect, useState , useRef, createRef } from "react";
import { useNavigate, useLocation,  } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Bars, Puff, SpinningCircles, Audio } from 'react-loading-icons' 
import {Helmet} from "react-helmet";

import { yHost }  from "./settings";


export default function Gpage() {
    const [ ansObj, setansObj] = useState({})
    const [loading, setloading ] = useState(true)
    const [totalMcqs, settotalMcqs] = useState(0)

    const {mcqnum} = useParams();
    

    useEffect(()=>{
      document.title = 'My Page Title';
      if(mcqnum.length==24){fetchThisMcqObj(mcqnum)}
        
    },[])

    const fetchThisMcqObj = (mcqnum) => {

      
        axios({
          method: 'post',
          url: `${yHost}/fetchThisMcqObj`,
          data: {mcqnum:mcqnum},
          withCredentials:true
      
        })
          .then((response) => {
            const data = response.data; 
            
            
            if(data.msg=="success"){
            setloading(false)
            setansObj(data.mcqobj)
            settotalMcqs(data.count)
             }else{
              
              alert(data.msg)
            }
  
           
          
          })
          .catch((error) => console.log(error));
      }

var strLink = "http://mcqbuster.com/gPage/"+ mcqnum

  return (
    <div style={{ backgroundColor: "white", marginLeft: "10px", marginRight: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
          

      <Helmet>
        <title>{ansObj.question}</title>
      </Helmet>

      <h2 style={{textAlign:"center", color:"orange"}}>MCQ Buster</h2>
      {loading ?
        <div style={{ width: "100%", height: "3000px", backgroundColor: "brown", position: 'absolute' }}>
          <Audio style={{ marginRight: "auto", marginLeft: "auto", display: "block", marginTop: "300px" }} stroke="#98ff98" strokeOpacity={.125} speed={.75} />
        </div>
        :

        <>
        
          {(ansObj.question.includes("<p") || ansObj.question.includes("<li") || ansObj.question.includes("<span")) && ansObj.question.includes("</") ?
            ReactHtmlParser(ansObj.question)
            :
            <p>{ansObj.question}</p>}

      <img src={yHost+"/userPics/" +   ansObj.postedByProfilePic    } alt="pic" style={{width:"50px", height:"50px", verticalAlign:"middle",borderRadius:"50%", marginRight:"10px"}}/>

          <p style={{ color: "orange", fontSize: "12px", marginBottom: "10px" }}>posted by {ansObj.postedBy} at {moment(ansObj.createdOn).fromNow()}</p>

          {ansObj.contriQuespic ?
            <img src={yHost + "/uploads/" + ansObj.contriQuespic} alt="Girl in a jacket" width="50" height="50" />
            : null}

          {ansObj.option1.includes("<") && ansObj.option1.includes("</") ?
            <p>A: {ReactHtmlParser(ansObj.option1)}</p>
            : <p>A: {ansObj.option1}</p>}

          {ansObj.option2.includes("<") && ansObj.option2.includes("</") ?
            <p>B: {ReactHtmlParser(ansObj.option2)}</p>
            : <p>B: {ansObj.option2}</p>}


          {ansObj.option3.includes("<") && ansObj.option3.includes("</") ?
            <p>C: {ReactHtmlParser(ansObj.option3)}</p>
            : <p>C: {ansObj.option3}</p>}


          {ansObj.option4.includes("<") && ansObj.option4.includes("</") ?
            <p>D: {ReactHtmlParser(ansObj.option4)}</p>
            : <p>D: {ansObj.option4}</p>}

          {ansObj.option6 ?
            ansObj.option5.includes("<") && ansObj.option5.includes("</") ?
              <p>E: {ReactHtmlParser(ansObj.option5)}</p>
              : <p>E: {ansObj.option5}</p>
            : null}

          {ansObj.option6 ?
            ansObj.option6.includes("<") && ansObj.option6.includes("</") ?
              <p>F: {ReactHtmlParser(ansObj.option6)}</p>
              : <p>F: {ansObj.option6}</p>
            : null}

          {ansObj.option7 ?
            ansObj.option7.includes("<") && ansObj.option7.includes("</") ?
              <p>G: {ReactHtmlParser(ansObj.option7)}</p>
              : <p>G: {ansObj.option7}</p>
            : null}

          {ansObj.option8 ?
            ansObj.option8.includes("<") && ansObj.option8.includes("</") ?
              <p>H: {ReactHtmlParser(ansObj.option8)}</p>
              : <p>H: {ansObj.option8}</p>
            : null}

          {ansObj.option9 ?
            ansObj.option9.includes("<") && ansObj.option9.includes("</") ?
              <p>I: {ReactHtmlParser(ansObj.option9)}</p>
              : <p>I: {ansObj.option9}</p>
            : null}

          {ansObj.option10 ?
            ansObj.option10.includes("<") && ansObj.option10.includes("</") ?
              <p>J: {ReactHtmlParser(ansObj.option10)}</p>
              : <p>J: {ansObj.option10}</p>
            : null}

          {ansObj.option11 ?
            ansObj.option11.includes("<") && ansObj.option11.includes("</") ?
              <p>K: {ReactHtmlParser(ansObj.option11)}</p>
              : <p>K: {ansObj.option11}</p>
            : null}

          {ansObj.option12 ?
            ansObj.option12.includes("<") && ansObj.option12.includes("</") ?
              <p>L: {ReactHtmlParser(ansObj.option12)}</p>
              : <p>L: {ansObj.option12}</p>
            : null}

          {ansObj.option13 ?
            ansObj.option13.includes("<") && ansObj.option13.includes("</") ?
              <p>M: {ReactHtmlParser(ansObj.option13)}</p>
              : <p>M: {ansObj.option13}</p>
            : null}

          {ansObj.option14 ?
            ansObj.option14.includes("<") && ansObj.option14.includes("</") ?
              <p>N: {ReactHtmlParser(ansObj.option14)}</p>
              : <p>N: {ansObj.option14}</p>
            : null}

          {ansObj.option15 ?
            ansObj.option15.includes("<") && ansObj.option15.includes("</") ?
              <p>O: {ReactHtmlParser(ansObj.option15)}</p>
              : <p>O: {ansObj.option15}</p>
            : null}

          {ansObj.option16 ?
            ansObj.option16.includes("<") && ansObj.option16.includes("</") ?
              <p>P: {ReactHtmlParser(ansObj.option16)}</p>
              : <p>P: {ansObj.option16}</p>
            : null}

          {ansObj.option17 ?
            ansObj.option17.includes("<") && ansObj.option17.includes("</") ?
              <p>Q: {ReactHtmlParser(ansObj.option17)}</p>
              : <p>Q: {ansObj.option17}</p>
            : null}

          {ansObj.option18 ?
            ansObj.option18.includes("<") && ansObj.option18.includes("</") ?
              <p>R: {ReactHtmlParser(ansObj.option18)}</p>
              : <p>R: {ansObj.option18}</p>
            : null}

          {ansObj.option19 ?
            ansObj.option19.includes("<") && ansObj.option19.includes("</") ?
              <p>S: {ReactHtmlParser(ansObj.option19)}</p>
              : <p>S: {ansObj.option19}</p>
            : null}

          {ansObj.option20 ?
            ansObj.option20.includes("<") && ansObj.option20.includes("</") ?
              <p>T: {ReactHtmlParser(ansObj.option20)}</p>
              : <p>T: {ansObj.option20}</p>
            : null}

          <p>Right Answer : {ansObj.ans}</p>

          {ansObj.contriPostedExpl ?
            <>
              <h3 style={{ textDecoration: 'underline' }}>{ansObj.postedBy}'s explanation</h3> <br />
              <div>{ReactHtmlParser(ansObj.contriexpl.replace("http://mcqbuster.com/assets/uploads/user", yHost + '/uploads'))}</div>
            </> : null}

          {ansObj.contritextbref ?
            <p>Textbook reference posted by {ansObj.postedBy}: {ansObj.contritextbref}</p> : null}
          <hr />
          {ansObj.adminPostedExpl ?
            <>
              <h3 style={{ textDecoration: 'underline' }}>Admin's explanation</h3> <br />
              <div>{ReactHtmlParser(ansObj.adminexpl)}</div>
            </> :
            <>
              <p>Admin didn't posted his explanation yet.</p>
              <hr /> </>
          }

          {ansObj.userexplanations && ansObj.userexplanations.length ?
            <>
              <p>{ansObj.userexplanations.length} users posted their explanations</p>
              {ansObj.userexplanations.map((ansItem, ansIndex) => {
                return (
                  <div key={"anss" + ansIndex}>
                    <p style={{ color: "orchid" }}>{ansItem.fname} posted on {moment(ansItem.createdOn).fromNow()}</p>

                    {ansItem.anspic1 ?
                      <>
                        <img src={yHost + "/uploads/" + ansItem.anspic1} alt="t" style={{ width: "100%", maxWidth: "400px", height: "auto" }} />
                        <br /><br />
                      </>
                      : null}

                    {ansItem.anspic2 ?
                      <>
                        <img src={yHost + "/uploads/" + ansItem.anspic2} alt="t" style={{ width: "100%", maxWidth: "400px", height: "auto" }} />
                        <br /><br />
                      </>
                      : null}

                    <p>He say right answer is {ansItem.useranswer}</p>
                    <p>His explanation: {ReactHtmlParser(ansItem.expl)}</p>
                    {ansItem.textbref ? <p>His textbook reference: {ansItem.textbref}</p> : null}



                    <hr />
                  </div>
                )
              })}
            </> : null
          }

          {ansObj.allanswers && ansObj.allanswers.length ?
            <>
              <p>{ansObj.allanswers.length} other users tried to answer this mcq till today</p>

              {ansObj.allanswers.map((tItem, tIndex) => {
                return (
                  <p key={"ansObj" + tIndex}>{tIndex + 1}. {tItem.fname} posted answer is {tItem.ansbythisUser}</p>
                )
              })}
            </>

            : null
          }
          <br />






              <hr/>
              <div style={{backgroundColor:"black", width:"100%", height:"auto", marginTop:"20px", paddingTop:"20px"}}>
                <p style={{color:"orange", marginLeft:"20px"}}>MCQBuster has {totalMcqs} Mcqs, increasing daily. Most of Mcqs have explanations and textbook references.</p>
                <p style={{color:"orange", marginLeft:"20px"}}>MCQBuster recommends that users can access various editions of all major textbooks (given in Textbook references with MCQs) at <a href="https://medicallibrary.live" style={{textDecoration:"none", color:"aqua"}}>https://medicallibrary.live</a></p>
                <p style={{color:"orange", marginLeft:"20px"}}>These MCQs are posted by users like you, and answered by users.</p>
                <p style={{color:"orange", marginLeft:"20px"}}>These Mcqs are useful for Indian Medical Entrance Exams like NEETPG, AIIMS, PGI, JIPMER, NEETSS and Overseas exams like USMLE, MCCQE, AMC PLAB, Royal College Exams UK, and many others.</p>
              
                <p style={{color:"orange", marginLeft:"20px"}}>To access this MCQ Bank you can must make your account, available for website and android, for website visit our <a href="/" style={{textDecoration:"none", color:"aqua"}}>home page</a>, for android please <a href="https://play.google.com/store/apps/details?id=com.mcqbuster" style={{textDecoration:"none", color:"aqua"}}>click here</a>.</p>
                <p style={{color:"orange", marginLeft:"20px"}}>For your questions, you can mail us at mcqbuster@gmail.com</p>
              </div>










        </>}

    </div>
  );
}

